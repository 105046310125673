import { useAuth0 } from "@auth0/auth0-react"
import "./Login.css"

const Login = () => {
    const { loginWithRedirect } = useAuth0()

    return (
        <div className="Login">
            <button onClick={()=>loginWithRedirect()}>Sign Up/Log In</button>
        </div>
        
    )
}

export default Login