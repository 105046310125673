import { useState, useContext } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { SearchContext } from '../Context/SearchContext'
import SearchNovel from './SearchNovel'
import "./SearchForm.css"

function SearchForm(props) {
    const { user } = useAuth0()
    const [enteredKeyword, setEnteredKeyword] = useState("")
    const {searchKeyword, setSearchKeyword, setSearchKeywordUpdated } = useContext(SearchContext)
    const [results, setResults] = useState()
    const keywordChangeHandler = (event) =>{
        setEnteredKeyword(event.target.value)
    }
    const submitHandler = (event) => {
        event.preventDefault()
        props.showSubscription && props.showSubscriptionHandler()
        if (enteredKeyword !== searchKeyword) {
            setSearchKeywordUpdated(true)
            setSearchKeyword(enteredKeyword)
            setResults(<SearchNovel user={user}/>)
        }
    }
    return (
        <div>
            <div className="SearchForm">
                <form onSubmit={submitHandler}>
                    <div className="SearchForm__controls">
                        <div className="SearchForm__control">
                            <input type="text" placeholder="Search Novels" value={enteredKeyword} onChange={keywordChangeHandler} />
                        </div>
                        <div className="SearchForm__actions">
                            <button type="submit">🔍</button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="SearchResults">
                {!props.showSubscription &&  results}
            </div>
        </div>
    )
}

export default SearchForm