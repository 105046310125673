import { createContext, useState } from 'react'


export const SubscriptionContext = createContext()

export const SubscriptionProvider = ({ children }) => {
    const [userSubscription, setUserSubscription] = useState([])
    const [userSubscriptionUpdated, setUserSubscriptionUpdated] = useState(false)
    return (
        <SubscriptionContext.Provider value={{userSubscription, setUserSubscription, userSubscriptionUpdated, setUserSubscriptionUpdated}}>
            {children}
        </SubscriptionContext.Provider>
    )
}

export default SubscriptionProvider