import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react"
import './index.css';

// Auth0 Docs
// https://auth0.com/docs/authenticate/login/auth0-universal-login/new-experience#signup
// https://community.auth0.com/t/how-to-create-signup-button-in-react/55638/3


ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENTID}
        redirectUri={window.location.origin}
        screen_hint="signup"
    >
        <App />
    </Auth0Provider>,
    document.getElementById('root')
);
