import "./SearchResultLength.css"

const SearchResultLength = (props) => {
    if (props.searchResult) {
        return (
            <div className="SearchResultLength">
                <h3>{props.searchResult.length} results found for  "{props.keyword}"</h3>
            </div>
        )
    }
    return (
        <div className="SearchResultLength">
            {/* nothing will be shown */}
        </div>
    )
}

export default SearchResultLength