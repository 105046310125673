import { useState } from "react"
import {SubscriptionProvider} from "./components/Context/SubscriptionContext"
import { SearchProvider } from "./components/Context/SearchContext"
import InitApis from "./components/InitApis"
import UserAuth from "./components/User/UserAuth"
import SearchForm from "./components/Search/SearchForm"
import SubscriptionList from "./components/Subscription/SubscriptionList"
import "./App.css"
function App() {
  const[showSubscription, setShowSubscription] = useState(false)
  const showSubscriptionHandler = () => {
    setShowSubscription(!showSubscription)
  }
  return (
    <div className="NovelNotifier">
      <InitApis />
      <SubscriptionProvider>
        <SearchProvider>
          <UserAuth showSubscription={showSubscription} showSubscriptionHandler={showSubscriptionHandler} />
          <SearchForm showSubscription={showSubscription} showSubscriptionHandler={showSubscriptionHandler} />
          <SubscriptionList showSubscription={showSubscription} showSubscriptionHandler={showSubscriptionHandler}/>
        </SearchProvider>
      </SubscriptionProvider>
    </div>
  );
}

export default App;
