import "./SubscriptionButton.css"

const SubscriptionButton = (props) => {
    return (
        <div className="SubscriptionButton">
            <button onClick={() => props.showSubscriptionHandler()}>🔔</button>
        </div>
    )
}

export default SubscriptionButton