import { useState, useEffect, useContext } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import { SubscriptionContext } from '../Context/SubscriptionContext'
import { SearchContext } from '../Context/SearchContext'
import { FetchSubscription } from "../../utils/Subscription/Subscription"
import Card from "./SearchCard"
import SearchResultLength from './SearchResultLength'
import { Search,SortNovel } from '../../utils/Search/Search'
import "./SearchNovel.css"
function SearchNovel(props) {
    let sortedList
    const { user } = useAuth0()
    const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const { userSubscription, setUserSubscription, userSubscriptionUpdated, setUserSubscriptionUpdated } = useContext(SubscriptionContext)
    const {searchResults, setSearchResults, searchKeyword, searchKeywordUpdated, setSearchKeywordUpdated  } = useContext(SearchContext)
    const subUpdateHandler = () => {
        setUserSubscriptionUpdated(!userSubscriptionUpdated)
        FetchSubscription(user.email).then((subscription) => {
            setUserSubscription(subscription)
        })
    }
    const novelIdentifiers = userSubscription.map(item => {
        return item.novel_identifier
    })
    useEffect(() => {
        // Whenver useEffect happens, reset the loading state
        setIsLoaded(false)
        if (searchResults['count'] !== undefined && !searchKeywordUpdated) {
             setIsLoaded(true)
        } else {
            setSearchKeywordUpdated(false)
            Search(searchKeyword).then((result) => {
                setIsLoaded(true)
                result && setSearchResults(SortNovel(result))
            })
        }
    }, [searchKeyword])
    // by setting props.keyword at the end of the useEffect, it will only run 
    // when props.keyword value changes
    if (error) {
        return <div className="Error">Error: {error.message}</div>
    } else if (!isLoaded) {
        return <div className="Loading">Searching {searchKeyword}...</div>
    } else {
        if (searchResults['results']) {
            const cards = searchResults['results'].map(result => (
                {
                    ncode: result.ncode,
                    title: result.title,
                    story: result.story,
                    episodes: result.general_all_no,
                    points: result.global_point,
                    lastupdate: result.general_lastup,
                    keywords: result.keyword
                }
            ))
            return (
                <div>
                    <SearchResultLength searchResult={cards} keyword={searchKeyword}/>
                    <Card cards={cards} user={props.user} novelIdentifiers={novelIdentifiers} subUpdateHandler={subUpdateHandler} />
                </div>
            )
        }
        return (
            <div>
                <SearchResultLength searchResult={[]} />
                <Card cards={[]} />
            </div>
        )
    }
}

export default SearchNovel