import { Unsubscribe } from "../../utils/Subscription/Subscription"
import { sleep } from "../../utils/common/utils"
import "./SubscriptionCard.css"

const Card = (props) => {
    if (props.cards.length === 0) {
        return (null)
    }
    const unsubscribeHandler = (event) => {
        const deleted = Unsubscribe(props.user.email, event.target.value)
        if (deleted !== undefined) {
            deleted.then((data) => {
                if (data === "deleted") {
                    sleep(250).then(() => {
                        props.subUpdateHandler()
                    })
                }
            })
        }
    }
    const Cards = props.cards.map(card => {
        return (
            <div key={card.novel_identifier} className="Card">
                <div className="Card__title">
                    <a href={`https://ncode.syosetu.com/${card.novel_identifier}`}>{card.title}</a>
                </div>
                <div className="Card__description">
                    <div className="Card__misc-wrapper">
                        <div className="Card__misc">Platform:<br />
                            {card.novel_platform}</div>
                        <div className="Card__misc">Notification:<br />
                            {card.notify_method}</div>
                        <div className="Card__misc">Last Update:<br />
                            {card.general_lastup}</div>
                        <div className="Card__misc">
                            <button onClick={unsubscribeHandler} value={card.novel_identifier}>Unsubscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        )
            

    })
    return (
        <div className="Card__container">
            {Cards}
        </div>
    )
}

export default Card