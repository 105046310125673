import { useAuth0 } from "@auth0/auth0-react"
import "./Logout.css"

const Logout = () => {
    const { logout } = useAuth0()

    return (
        <div className="Logout">
            <button onClick={()=>logout({ returnTo: window.location.origin })}>Sign Out</button>
        </div>
    )
}

export default Logout