const Search = async (searchKeyword) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_NAROU_QUERIER}/search?keyword=${searchKeyword}`, {})
        const result = await res.json()
        return result
    } catch (error) {
        console.log("Error:", error)
        return false
    }
}

const SortNovel = (novels, sortBy = "rating") => {
    // sortedBy accepts:
    // rating - default, length (word counts), or liked (favorite counts)
    let sortedList = novels
    switch (sortBy) {
        case "rating":
            sortedList['results'] = novels['results'].sort((a, b) => (a.global_point > b.global_point) ? -1 : 1)
        case "length":
            sortedList['results'] = novels['results'].sort((a, b) => (a.length > b.length) ? -1 : 1)
        case "liked":
            sortedList['results'] = novels['results'].sort((a, b) => (a.fav_novel_cnt > b.fav_novel_cnt) ? -1 : 1)
        default:
            sortedList['results']= novels['results'].sort((a, b) => (a.global_point > b.global_point) ? -1 : 1)
        return sortedList
    }
}

export {Search, SortNovel}