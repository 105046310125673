import { createContext, useState } from 'react'

export const SearchContext = createContext()

export const SearchProvider = ({ children }) => {
    const [searchResults, setSearchResults] = useState([])
    const [searchKeyword, setSearchKeyword] = useState("")
    const [searchKeywordUpdated, setSearchKeywordUpdated] = useState(false)
    return (
        <SearchContext.Provider value={{ searchResults, setSearchResults, searchKeyword, setSearchKeyword, searchKeywordUpdated, setSearchKeywordUpdated }}>
            {children}
        </SearchContext.Provider>
    )
}

export default SearchProvider