import { useState, useEffect, useContext } from 'react'
import { SubscriptionContext } from '../Context/SubscriptionContext'
import { FetchSubscription } from "../../utils/Subscription/Subscription"
import Card from './SubscriptionCard'

const GetSubscription = (props) => {
    // const [error, setError] = useState(null)
    const [isLoaded, setIsLoaded] = useState(false)
    const [items, setItems] = useState([])
    const { userSubscription, setUserSubscription, userSubscriptionUpdated, setUserSubscriptionUpdated } = useContext(SubscriptionContext)
    const subUpdateHandler = () => {
        setUserSubscriptionUpdated(!userSubscriptionUpdated)
    }
    useEffect(() => {
        if (props.user === undefined) {
            return
        }
        if (userSubscriptionUpdated === true) {
            FetchSubscription(props.user.email).then((subscription) => {
                subscription && setUserSubscription(subscription)
            })
            subUpdateHandler()
        }
        props.isAuthenticated && setIsLoaded(true)
        setItems(userSubscription)
    }, [ userSubscriptionUpdated, userSubscription])
    // if (error) {
    //     return <div className="Subscriptions">
    //         <h2>Error: {error.message}</h2>
    //     </div>
    // } else
    if (props.user && !isLoaded) {
        return <div className="Subscriptions">
            <h2>Loading Your Subscriptions...</h2>
        </div>
    } else {
        if (props.user && items.length !== 0) {
            return (
                <div className="Subscriptions">
                    <h2> Your Subscriptions 🔔</h2>
                    <Card cards={items} user={props.user} subUpdateHandler={subUpdateHandler} />
                </div>
            )
        }
        return (
            <div className="Subscriptions">
            </div>
        )
    }
}

export default GetSubscription