import {useContext} from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import Login from "./Login"
import Logout from "./Logout"
import SubscriptionButton from "./SubscriptionButton"
import { FetchSubscription } from "../../utils/Subscription/Subscription"
import { SubscriptionContext } from '../Context/SubscriptionContext'
import "./UserAuth.css"

const UserAuth = (props) => {
    const { user, isAuthenticated, isLoading } = useAuth0()
    const { userSubscription, setUserSubscription } = useContext(SubscriptionContext)
    if (isLoading) {
        return (
            <div className="UserAuth">
                <p>Loading...</p>
            </div>
        )
    }
    if (!isAuthenticated) {
        return (
            <div className="UserAuth">
                <Login />
            </div>
        )
    }
    FetchSubscription(user.email).then((subscription) => {
        if (userSubscription.length === 0) {
            setUserSubscription(subscription)
        }
    })
    // console.log(userSubscription)
    // Looks like this component keeps rendering for some reason
    // Will look into why this happens later
    return (
        <div className="UserAuth">
            <p>{user.email}</p>
            <SubscriptionButton  showSubscriptionHandler={props.showSubscriptionHandler} />
            <Logout />
        </div>
    )
}

export default UserAuth