import { useRef } from 'react'
import { useAuth0 } from "@auth0/auth0-react"
import GetSubscription from "./GetSubscription"
import { sleep } from '../../utils/common/utils'
import "./SubscriptionList.css"

const SubscriptionList = (props) => {
    const { user, isAuthenticated } = useAuth0()
    // this is to show the subscription on load only when the user is logged in
    const showSubscriptionOnLoad = useRef(true)
    // check if the user is logged in
    // if (isLoading === true && isAuthenticated === false && showSubscriptionOnLoad.current === true) {
    //     // 
    //     sleep(1).then(() => {
    //         props.showSubscriptionHandler()
    //         showSubscriptionOnLoad.current = false
    //     })
    // }
    if (showSubscriptionOnLoad.current === true) {
        sleep(10).then(() => {
            props.showSubscriptionHandler()
            // showSubscriptionOnLoad.current = false
        })
        showSubscriptionOnLoad.current = false
    }
    if (!user || !props.showSubscription) {
        return (null)
    }
    return (
        <div className="SubscriptionList">
            <GetSubscription user={user} isAuthenticated={isAuthenticated} />
        </div>
    )
}

export default SubscriptionList
