import { Subscribe } from "../../utils/Subscription/Subscription"
import { sleep } from "../../utils/common/utils"
import "./SearchCard.css"


const Card = (props) => {
    if (props.cards.length === 0) {
        return (null)
    }
    const subscribeHandler = (event) => {
        const updated = Subscribe(props.user.email, event.target.value)
        if (updated !== undefined) {
            updated.then((data) => {
                if (data === "created") {
                    sleep(250).then(() => {
                        props.subUpdateHandler()
                    })
                }
            })
        }
    }
    const subscribeButton = (ncode) => {
        if (props.user !== undefined && !props.novelIdentifiers.includes(ncode)) {
            return (
                <div className="Card__widget">
                    <button onClick={subscribeHandler} value={ncode}>Subscribe</button>
                </div>
            )
        }
        return (null)
    }
    const Cards = props.cards.map(card => {
        return (
            <div key={card.ncode} className="Card">
                <div className="Card__subject">
                    <div className="Card__title">
                        <a href={`https://ncode.syosetu.com/${card.ncode}`}>{card.title}</a>
                    </div>
                    {subscribeButton(card.ncode)}
                </div>
                
                <div className="Card__description">
                    <div className="Card__description-story">{card.story}</div>
                    <div className="Card__misc-wrapper">
                        <div className="Card__misc">Total Points:<br />
                            {card.points}</div>
                        <div className="Card__misc">Total Episodes:<br />
                            {card.episodes}</div>
                        <div className="Card__misc">Last Update:<br />
                            {card.lastupdate}</div>
                        <div className="Card__misc">Keywords:<br />
                            {card.keywords}</div>
                    {/* {subscribeButton(card.ncode)} */}
                    </div>
                </div>
            </div>
        )
            

    })
    return (
        <div className="Card__container">
            {Cards}
        </div>
    )
}

export default Card