import { useState, useEffect } from 'react' 

const InitApis = () => {
    const [isSearchNovelLoaded, setIsSearchNovelLoaded] = useState(false)
    const [isSubsLoaded, setIsSubsLoaded] = useState(false)
    const [isSetError, setIsSetError] = useState(null)
    useEffect(() => {
        // load search novel api
         fetch(`${process.env.REACT_APP_NAROU_QUERIER}/search?keyword=test`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    setIsSearchNovelLoaded(true)
                },
                (error) => {
                    setIsSearchNovelLoaded(true)
                    setIsSetError(error)
                    // console.log(isSetError)
                }
        )
    }, [isSearchNovelLoaded])

    useEffect(() => {
        // load user subscription api
        // need 
         fetch(`${process.env.REACT_APP_SUBS}/fetch-subs?username=${process.env.REACT_APP_TEST_User}`, {})
            .then(res => res.json())
            .then(
                (result) => {
                    setIsSubsLoaded(true)
                },
                (error) => {
                    setIsSubsLoaded(true)
                    setIsSetError(error)
                    // console.log(isSetError)
                }
         )
    }, [isSubsLoaded])
    return (isSetError)
}

export default InitApis