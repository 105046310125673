const Subscribe = (email, novel_identifier) => {
    const response = fetch(`${process.env.REACT_APP_SUBS}/update-sub?username=${email}&novel_identifier=${novel_identifier}`, {})
        .then(res => res.json())
        .then((result) => {
            // console.log(result)
            return result.result
        }
    )
        .catch((error) => {
            console.log("Error:", error)
            return false
        })
    return response
}

// export default Subscribe

const FetchSubscription = async (email) => {
    try {
        const res = await fetch(`${process.env.REACT_APP_SUBS}/fetch-subs?username=${email}`, {})
        const result_1 = await res.json()
        return result_1
    } catch (error) {
        console.log("Error:", error)
        return false
    }
}

// export default FetchSubscription

const Unsubscribe = (email, novel_identifier) => {
    const response = fetch(`${process.env.REACT_APP_SUBS}/delete-sub?username=${email}&novel_identifier=${novel_identifier}`, {})
        .then(res => res.json())
        .then((result) => {
            // console.log(result)
            return result.result
        }
    )
        .catch((error) => {
            console.log("Error:", error)
            return false
        })
    return response
}

// export default Unsubscribe
export { Subscribe,FetchSubscription, Unsubscribe }
